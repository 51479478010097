// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyADE7tonoJsF2tWoIPaaFwIEORyiLqd43M",
  authDomain: "code-mngr.firebaseapp.com",
  projectId: "code-mngr",
  storageBucket: "code-mngr.appspot.com",
  messagingSenderId: "377758922134",
  appId: "1:377758922134:web:2f61418c368f0f1f0859b7",
  measurementId: "G-KM799EKJPR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
import React from 'react'
import QRCode from 'react-qr-code'

const BigCode = ({link}) => {
  return (
    <div className='w-full h-full flex justify-center items-center'>
        <QRCode value={link} className='w-full h-full'/>
    </div>
  )
}

export default BigCode
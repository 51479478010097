import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';

const Login = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
       
    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((_) => {
            navigate('/manager');
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
       
    }

  return (
    <div style={{background: 'url(space-dish.jpeg)', backgroundSize: 'cover'}} className="w-full h-full flex items-center justify-center">
        <div className="rounded-lg bg-white p-4 flex justify-center flex-col ">

            <label htmlFor="login_email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
            <input className='mb-4 border p-2 rounded-md border-slate-300' id="login_email" onChange={(e) => setEmail(e.currentTarget.value)} type="email"/>
            <label htmlFor="login_password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
            <input className='mb-4 border p-2 border-slate-300 rounded-md' id="login_password" onChange={(e) => setPassword(e.currentTarget.value)} type="password"/>
            <button onClick={onLogin} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>Login</button>
        </div>
    </div>
  )
}

export default Login
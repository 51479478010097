import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { db } from './firebase';
import Map from './Map';
import Spinner from './Spinner';

const CodeMgr = () => {

    const [userLoading, setUserLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(true);
    const [error, setError] = useState("");
    const [codes, setCodes] = useState(null);
    const [clues, setClues] = useState(null);
    const [redirects, setRedirects] = useState(null);

    const auth = getAuth();
    const navigate = useNavigate();
      

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setUserLoading(false);
        } 
        // Not signed in
        if (auth.currentUser == null) {
            navigate('/login');
            return <></>;
        }
    });

    const logout = () => {
        signOut(auth);
        navigate("/login")
    }

    // Allow for local caching of updates
    const updateRedirects = (id, new_ob) => {
        redirects[id] = new_ob;
    }

    const fetchData = async () => {
        setDataLoading(true);
        try {
            const _codes = await getDocs(collection(db, "codes"));
            const new_data = [];
            _codes.forEach((doc) => {
                new_data.push(doc.data());
            });

            setCodes(new_data);
        } catch(err) {
            setError(err.message);
        }
        try {
            const _clues = await getDocs(collection(db, "clues"));
            const new_data = {};
            _clues.forEach((doc) => {
                new_data[doc.id] = doc.data();
            });
            setClues(new_data);

        } catch(err) {
            setError(err.message);
        }
        try {
            const _redirects = await getDocs(collection(db, "redirects"));
            const new_data = {};
            _redirects.forEach((doc) => {
                new_data[doc.id] = doc.data();
            });
            setRedirects(new_data);

        } catch(err) {
            setError(err.message);
        }
        setDataLoading(false);
    }

    useEffect(() => {
        if(auth.currentUser) fetchData();
    }, [auth.currentUser]);

    if (userLoading || codes === null) return (<div className='w-full h-full flex justify-center items-center'><Spinner/></div>);
    if (error !== "") return (<div className='w-full h-full flex justify-center items-center'><h2><code>Error: {error}</code></h2></div>);
    if (codes.size === 0) return (<div className='w-full h-full flex justify-center items-center'><h2><code>Couldn't find data</code></h2></div>);

    return (<div className='bg-black w-full h-full relative'>
        <div className='absolute top-0 right-0 m-4 rounded-lg bg-white z-20'>
            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded' onClick={logout}>Logout</button>
        </div>
        {codes && <Map points={codes} clues={clues} redirects={redirects} updateRedirects={updateRedirects}/>}
    </div>)
}

export default CodeMgr;
import './App.css';

function App() {


  return (
    <div className="App w-full h-full flex justify-center items-center">
      <code>Congrats!<br/>You've found my little game :)</code>
    </div>
  );
}

export default App;

import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { GoPrimitiveDot } from "react-icons/go";
import QRCode from 'react-qr-code';
import { db } from '../firebase';
import MiniCard from '../MiniCard';
import Dropdown from './Dropdown';

const Modal = ({modalData, setShowModal, clues, redirects, updateRedirects}) => {
    const {name, link, status} = modalData;

    const originalClue = redirects[link.split("/")[2]]["to"].split("/")[2];
    const [currentClue, setCurrentClue] = useState(originalClue);

    const setNewRedir = async () => {
      if (currentClue === originalClue) return;
      
      const docRef = doc(db, 'redirects', link.split("/")[2]);
      updateDoc(docRef, {"to": "/clue/"+currentClue});
      updateRedirects(link.split("/")[2], {"to": "/clue/"+currentClue});
    }

    const saveClick = () => {
      setNewRedir();
      setShowModal(false);
    }

    return (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl" onClick={(e) => e.stopPropagation()}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {name}
                    </h3>
                    <h4>{<GoPrimitiveDot color={status === "Active" ? 'green' : (status === "Review" ? 'yellow' : 'red')} className='inline'/>}{status}</h4>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <div className='flex flex-row space-x-4'>
                      <div className='flex flex-col space-y-2'>
                        <h2>Link</h2>
                        {/*<input className="w-auto fit-content p-2 border border-blue-300 rounded-md" type="text" value={linkValue} onChange={e => {setLinkValue(e.currentTarget.value)}}/>*/}
                        <Dropdown current={currentClue} setCurrent={setCurrentClue} options={clues}/>
                        <QRCode className='p-4' value={'http://www.qrnet.space' + link}/>
                      </div>
                      <div className='flex justify-center items-center w-1/2 min-w-[300px] overflow-hidden rounded-md'>
                        <MiniCard bg={clues[currentClue]["bg-colour"]} text={clues[currentClue]["text"]} className="w-full h-full"/>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white active:bg-blue-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={saveClick}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )
}

export default Modal;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Redir from './Redir';
import Card from './Card';
import Login from './Login';
import CodeMgr from './CodeMgr';
import CodeLink from './CodeLink';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}/>
        <Route exact path='/redir/:r' element={<Redir />}/>
        <Route exact path='/redir/:r/code' element={<CodeLink/>}/>
        <Route path='/clue/:c' element={<Card />}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/manager' element={<CodeMgr />}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { db } from "./firebase";
import Spinner from "./Spinner";

const Card = () => {

    const {c} = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [text, setText] = useState("");
    const [bg, setBg] = useState("");

    const fetchData = async () =>{
        const docRef = doc(db, "clues", c);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) setError(true);
        setText(docSnap.get("text"));
        setBg(docSnap.get("bg-colour"));

        setLoading(false);
    }

    useEffect(() =>{
        fetchData();
    });

    if (loading) return (
        <div style={{background: bg}} className="w-full h-full flex justify-center items-center">
            <Spinner/>
        </div>);
    if (error) return <h1>Error - 404</h1>

    return (
        <div style={{background: bg}} className="w-full h-full flex justify-center items-center">
            <div className="p-6 rounded-lg shadow-lg bg-white max-w-sm flex justify-center h-fit">
                <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">{text}</h5>
            </div>
        </div>
    )
}

export default Card
import React from "react";
import MapIcon from "./MapIcon";

const Marker = (props, ref) => {
    const {onClick, point} = props;
    const _onClick = () => {
        onClick(point);
    };

    return (
        <button onClick={_onClick} ref={ref}>
            <MapIcon height="40px" width="40px"/>
        </button>
    );
};

export default React.forwardRef(Marker);
import { useEffect, useState } from 'react'
import { redirect, useParams} from 'react-router-dom'
import {db} from './firebase'
import { doc, getDoc } from "firebase/firestore";
import Spinner from './Spinner';


const Redir = () => {

    const {r} = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const fetchData = async () =>{
        const docRef = doc(db, "redirects", r);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()){
             window.location.replace(docSnap.get('to'));
        } else {
            setError(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) return (
        <div className="w-full h-full flex justify-center items-center">
            <Spinner/>
        </div>);
    if (error) return <h1>Not found</h1>

    return <div></div>;
}

export default Redir
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import Marker from "./MapComponents/MapMarker";
import Modal from "./util/Modal";

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
mapboxgl.accessToken = "pk.eyJ1IjoiLWFpZGFuIiwiYSI6ImNsZHFvZXhrdzFleHAzcnE3cmtreG12bXgifQ.LMxmJKjvylTVZpcqTiGudA";

mapboxgl.workerClass = MapboxWorker;

const Map = ({points, clues, redirects, updateRedirects}) => {
    const mapContainer = useRef(null);
    const markerDiv = useRef(null);

    const map = useRef(null);
    const [lng, setLng] = useState(149.0486562143209);
    const [lat, setLat] = useState(-35.20829849809781);
    const [zoom, setZoom] = useState(15);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({name:"",link:"",status:""});

    const markerClicked = (point) => {
        setShowModal(true);
        setModalData(point);
    };

     
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom
        });

        points.forEach((point) => {

            const ref = React.createRef();
            ref.current = document.createElement("div");
            
            ReactDOM.render(
                <Marker onClick={markerClicked} point={point}/>,
                ref.current
            );

            new mapboxgl.Marker(ref.current)
            .setLngLat([point.current_location._long, point.current_location._lat])
            .addTo(map.current);
        });
    });
     
    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });
     
    return (
        <div className="w-full h-full absolute z-10">
            {showModal && <Modal updateRedirects={updateRedirects} redirects={redirects} clues={clues} modalData={modalData} setShowModal={setShowModal}/>}
            <div ref={markerDiv} className="z-10"></div>
            <div ref={mapContainer} className="w-full h-full map-container z-0" />
        </div>
    );
}

export default Map;
import React from 'react'

const Dropdown = ({current, setCurrent, options}) => {

    return (
        <>
            <select onChange={((e) => {setCurrent(e.currentTarget.value)})} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                {Object.keys(options).map((op) => (<option className='text-gray-900' selected={op === current} value={op} key={op}>{op}</option>))}
            </select>
        </>
    )
}

export default Dropdown
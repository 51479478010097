const MiniCard = ({bg, text}) => {

    return (
        <div style={{background: bg}} className="w-full h-full flex justify-center items-center">
            <div className="p-4 rounded-lg shadow-lg bg-white max-w-sm flex justify-center items-center">
                <h5 className="text-gray-900 text-sm leading-tight font-medium mb-2">{text}</h5>
            </div>
        </div>
    )
}

export default MiniCard
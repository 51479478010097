import React from 'react'
import { useParams } from 'react-router-dom';
import BigCode from './BigCode';

const CodeLink = () => {
    const {r} = useParams();
    if (!r) return <></>;

    return (
        <BigCode link={"www.qrnet.space/redir/" + r}/>
    )
}

export default CodeLink;